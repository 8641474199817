import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Image,
  Dimensions,
  ActivityIndicator,
  SafeAreaView,
  Modal,
  FlatList,
  RefreshControl,
} from 'react-native';
import { firestore } from '../../firebaseConfig';
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { auth } from '../../firebaseConfig';
import { useNavigation } from '@react-navigation/native';
import VendorProfileScreen from './VendorProfileScreen';
import VendorCard from './VendorCard';
import { Ionicons } from '@expo/vector-icons';
import { PinchGestureHandler, GestureEvent, PinchGestureHandlerEventPayload } from 'react-native-gesture-handler';
import Animated, { useAnimatedGestureHandler, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';

// Utility function to get currency symbol based on location
const getCurrencySymbolForLocation = (location: string | null): string => {
  switch (location) {
    case 'Bali': return 'IDR';
    case 'Bangkok': return 'THB';
    case 'Copenhagen': return 'DKK';
    case 'Florence': return 'EUR';
    case 'London': return 'GBP';
    case 'Nice': return 'EUR';
    case 'New York': return 'USD';
    case 'Paris': return 'EUR';
    case 'Tokyo': return 'JPY';
    default: return 'USD';
  }
};

const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export default function ExplorePage() {
  const [posts, setPosts] = useState<Array<{ image: string; caption: string; price: string; aspectRatio: number; vendorId: string; vendorName: string }>>([]);
  const [favoritePosts, setFavoritePosts] = useState<Array<{ image: string; caption: string; price: string; aspectRatio: number; vendorId: string; vendorName: string; timestamp: number }>>([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<'discover' | 'favorites'>('discover');
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedPost, setSelectedPost] = useState<any>(null);
  const [selectedVendor, setSelectedVendor] = useState<any>(null);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [vendorModalVisible, setVendorModalVisible] = useState(false);
  const [favorites, setFavorites] = useState<string[]>([]);
  const [favoriteVendors, setFavoriteVendors] = useState<any[]>([]);
  const [refreshing, setRefreshing] = useState(false);

  const currentUser = auth.currentUser;
  const navigation = useNavigation();
  const scale = useSharedValue(1);

  const pinchGestureHandler = useAnimatedGestureHandler<GestureEvent<PinchGestureHandlerEventPayload>>({
    onActive: (event) => {
      scale.value = event.scale;
    },
    onEnd: () => {
      scale.value = withSpring(1, { damping: 5 });
    },
  });

  const animatedImageStyle = useAnimatedStyle(() => ({
    transform: [{ scale: scale.value }],
  }));

  const fetchUserPreferences = async (): Promise<void> => {
    if (currentUser) {
      try {
        const userRef = doc(firestore, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setSelectedLocation(userData.location || null);
          setSelectedCategories(userData.categories || []);
          setFavorites(userData.favorites || []);
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    }
  };

  const fetchVendorPosts = async (): Promise<void> => {
    if (!selectedLocation || selectedCategories.length === 0) {
      setLoading(false);
      return;
    }

    try {
      const vendorsRef = collection(firestore, 'vendors');
      const vendorQuery = query(
        vendorsRef,
        where('location', '==', selectedLocation),
        where('category', 'in', selectedCategories)
      );
      const querySnapshot = await getDocs(vendorQuery);

      let allPosts: Array<{ image: string; caption: string; price: string; aspectRatio: number; vendorId: string; vendorName: string }> = [];
      for (const vendorDoc of querySnapshot.docs) {
        const vendorData = vendorDoc.data();
        if (vendorData.posts && vendorData.posts.length > 0) {
          vendorData.posts.forEach((post: { image: string; caption: string; price: string; aspectRatio: number }) => {
            allPosts.push({
              ...post,
              vendorId: vendorDoc.id,
              vendorName: vendorData.vendorName || 'Unknown Vendor',
            });
          });
        }
      }

      setPosts(shuffleArray(allPosts));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const onRefresh = async (): Promise<void> => {
    setRefreshing(true);
    await fetchUserPreferences();
    await fetchVendorPosts();
    setRefreshing(false);
  };

  useEffect(() => {
    fetchUserPreferences();
  }, [currentUser]);

  useEffect(() => {
    if (selectedLocation && selectedCategories.length > 0) {
      fetchVendorPosts();
    }
  }, [selectedLocation, selectedCategories]);


  // Fetch favorite vendors' details based on their IDs
  const fetchFavoriteVendors = async () => {
    const vendorData = [];
    const allFavoritePosts = [];

    for (const vendorId of favorites) {
      try {
        const vendorRef = doc(firestore, 'vendors', vendorId);
        const vendorSnap = await getDoc(vendorRef);
        if (vendorSnap.exists()) {
          const vendor = vendorSnap.data();
          if (vendor.location === selectedLocation) {
            vendorData.push({ ...vendor, userId: vendorId });

            // Collect all posts from favorite vendors
            if (vendor.posts && vendor.posts.length > 0) {
              vendor.posts.forEach((post) => {
                allFavoritePosts.push({
                  ...post,
                  vendorId,
                  vendorName: vendor.vendorName || 'Unknown Vendor',
                  timestamp: post.timestamp || 0,
                });
              });
            }
          }
        }
      } catch (error) {
        console.error('Error fetching favorite vendor:', error);
      }
    }

    setFavoriteVendors(vendorData); // Store the fetched favorite vendors
    setFavoritePosts(allFavoritePosts.sort((a, b) => b.timestamp - a.timestamp)); // Sort favorite posts by newest
  };

  // Handle tab change and fetch favorite vendors when switching to "Favorites" tab
  const handleTabChange = async (tab: 'discover' | 'favorites') => {
    setActiveTab(tab);
    if (tab === 'favorites') {
      await fetchFavoriteVendors();
    }
  };
  

  const getGridColumns = () => {
    const screenWidth = Dimensions.get('window').width;
    return screenWidth > 768 ? 4 : 2; // 4 posts per row on web, 2 posts on mobile
  };

  // Handle when post is clicked, show the image modal
  const handlePostClick = (post: any) => {
    setSelectedPost(post); // Set the selected post
    setImageModalVisible(true); // Open image modal
  };

  // Close image modal
  const closeImageModal = () => {
    setImageModalVisible(false);
    setSelectedPost(null);
  };

  // Navigate to the vendor profile from the image modal
  const handleVisitVendor = async (vendorId: string) => {
    try {
      const vendorRef = doc(firestore, 'vendors', vendorId);
      const vendorSnap = await getDoc(vendorRef);
      if (vendorSnap.exists()) {
        const vendorData = vendorSnap.data();
        setSelectedVendor(vendorData); // Set selected vendor for vendor profile
        setImageModalVisible(false); // Close image modal
        setVendorModalVisible(true); // Open vendor modal
      } else {
        console.error('Vendor not found');
      }
    } catch (error) {
      console.error('Error fetching vendor details:', error);
    }
  };

  // Close vendor modal
  const closeVendorModal = () => {
    setVendorModalVisible(false);
    setSelectedVendor(null);
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size='large' color="#b6cc9b" />
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView contentContainerStyle={styles.container}refreshControl={
    <RefreshControl refreshing={refreshing} onRefresh={onRefresh} colors={['#b6cc9b']} />
  }>
        {/* Buttons Section */}
        <View style={styles.buttonsContainer}>
          <TouchableOpacity style={buttonStyle(activeTab, 'discover')} onPress={() => handleTabChange('discover')}>
            <Text style={buttonTextStyle(activeTab, 'discover')}>Discover</Text>
          </TouchableOpacity>
          <TouchableOpacity style={buttonStyle(activeTab, 'favorites')} onPress={() => handleTabChange('favorites')}>
            <Text style={buttonTextStyle(activeTab, 'favorites')}>Favorites</Text>
          </TouchableOpacity>
        </View>

        {/* Discover Tab */}
        {activeTab === 'discover' && posts.length > 0 ? (
          <View style={styles.postsGrid}>
            {posts.map((post, index) => (
              <TouchableOpacity key={index} style={[styles.postCard, { width: `${100 / getGridColumns() - 2}%` }]} onPress={() => handlePostClick(post)}>
                <Image
  source={{
    uri: post.image,
    cache: 'force-cache',
  }}
  style={[
    styles.postImage,
    {
      width: '100%',
      height: Math.min(
        Dimensions.get('window').width / (post.aspectRatio || 1),
        300
      ),
    },
  ]}
                  resizeMode="cover"
                />
                <View style={styles.postInfo}>
                  {post.vendorName ? <Text style={styles.vendorName}>{post.vendorName}</Text> : null}
                  {post.price ? (
                    <Text style={styles.postPrice}>
                      {getCurrencySymbolForLocation(selectedLocation)} {post.price}
                    </Text>
                  ) : null}
                </View>
              </TouchableOpacity>
            ))}
          </View>
        ) : (
          <View style={styles.noPostsContainer}>
            <Text></Text>
          </View>
        )}

        {/* Favorites Tab */}
{activeTab === 'favorites' && (
  <View>
    {/* Explanation Box */}
    <View style={styles.explanationBox}>
      <Text style={styles.explanationText}>
        To add a place to your favorites, tap the{' '}
        <Ionicons name="heart-outline" size={18} color="#FFB6C1" /> button in the top right corner on their profile.
      </Text>
    </View>

    <View>
      <Text style={styles.sectionTitle}>Your Favorites</Text>
      <FlatList
        data={favoriteVendors}
        keyExtractor={(item) => item.userId}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => (
          <TouchableOpacity style={styles.vendorCardContainer} onPress={() => handleVisitVendor(item.userId)}>
            <VendorCard vendor={item} />
          </TouchableOpacity>
        )}
      />
      <View style={styles.postsGrid}>
        {favoritePosts.length > 0 ? (
          favoritePosts.map((post, index) => (
            <TouchableOpacity
              key={index}
              style={[styles.postCard, { width: `${100 / getGridColumns() - 2}%` }]}
              onPress={() => handlePostClick(post)}
            >
              <Image
  source={{
    uri: post.image,
    cache: 'force-cache',
  }}
  style={[
    styles.postImage,
    {
      width: '100%',
      height: Math.min(
        Dimensions.get('window').width / (post.aspectRatio || 1),
        300
      ),
    },
  ]}
                resizeMode="cover"
              />
            </TouchableOpacity>
          ))
        ) : (
          <Text style={styles.noFavoritesText}>You have no favorites in this location.</Text>
        )}
      </View>
    </View>
  </View>
)}


        <Modal visible={imageModalVisible} transparent={true} animationType="slide" onRequestClose={closeImageModal}>
          {selectedPost && (
            <View style={styles.modalContainer}>
              <PinchGestureHandler onGestureEvent={pinchGestureHandler}>
                <Animated.Image
                  source={{ uri: selectedPost.image, cache: 'force-cache', }}
                  style={[styles.fullImage, animatedImageStyle]}
                  resizeMode="contain"
                />
              </PinchGestureHandler>
              <Text style={styles.fullCaption}>{selectedPost.caption}</Text>
              {selectedPost.price && (
                <Text style={styles.fullPrice}>
                  {getCurrencySymbolForLocation(selectedLocation)} {selectedPost.price}
                </Text>
              )}
              <TouchableOpacity style={styles.modalButton} onPress={() => handleVisitVendor(selectedPost.vendorId)}>
                <Text style={styles.buttonText}>Visit Vendor</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.modalButton} onPress={closeImageModal}>
                <Text style={styles.buttonText}>Close</Text>
              </TouchableOpacity>
            </View>
          )}
        </Modal>

        <Modal visible={vendorModalVisible} animationType="slide" onRequestClose={closeVendorModal}>
          {selectedVendor && <VendorProfileScreen vendor={selectedVendor} isExplorer={true} onClose={closeVendorModal} />}
        </Modal>
      </ScrollView>
    </SafeAreaView>
  );
}

const buttonStyle = (activeTab, tab) => ({
  backgroundColor: activeTab === tab ? '#b6cc9b' : '#FFFFFF',
  paddingVertical: 12,
  paddingHorizontal: 30,
  borderRadius: 15,
  marginHorizontal: 5,
  borderWidth: 1,
  borderColor: '#b6cc9b',
});

const buttonTextStyle = (activeTab, tab) => ({
  color: activeTab === tab ? '#000000' : '#000000',
  fontWeight: '600',
  fontSize: 16,
});

const styles = StyleSheet.create({
  safeArea: { flex: 1, backgroundColor: '#fff' },
  container: { backgroundColor: '#fff', paddingVertical: 20 },
  loadingContainer: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  buttonsContainer: { flexDirection: 'row', justifyContent: 'center', marginBottom: 20 },
  postsGrid: { flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', paddingHorizontal: 15 },
  postCard: { borderRadius: 10, overflow: 'hidden', marginBottom: 10, elevation: 3 },
  postImage: { width: '100%', borderRadius: 10 },
  postInfo: { padding: 5, alignItems: 'center' },
  vendorName: { fontSize: 14, color: '#333', textAlign: 'center', fontWeight: 'bold' },
  postPrice: { fontSize: 16, color: '#555', textAlign: 'center', fontWeight: 'bold' },
  noPostsContainer: { alignItems: 'center', justifyContent: 'center', padding: 20 },
  modalContainer: { flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.7)', padding: 20 },
  fullImage: { width: '100%', height: 400, marginBottom: 15 },
  fullCaption: { fontSize: 18, color: '#FFF', marginVertical: 10, textAlign: 'center' },
  fullPrice: { fontSize: 18, color: '#93C572', fontWeight: 'bold' },
  modalButton: { backgroundColor: '#b6cc9b', paddingVertical: 10, paddingHorizontal: 20, borderRadius: 15, marginVertical: 5 },
  buttonText: { color: '#000000', fontWeight: '600', fontSize: 16 },
  sectionTitle: { fontSize: 18, fontWeight: 'bold', color: '#4A4A4A', paddingVertical: 10, marginLeft: 15 },
  noFavoritesText: { color: '#333', fontSize: 16, padding: 10, textAlign: 'center' },
  vendorCardContainer: { marginRight: 0, marginLeft: 15, marginBottom: 20 },
  explanationBox: {
    backgroundColor: '#fff',
    padding: 10,
    marginHorizontal: 15, // Only horizontal margins
    marginBottom: 10, // No top margin to avoid extra space
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#b6cc9b',
  },
  
  explanationText: {
    fontSize: 16,
    color: '#333',
    textAlign: 'center',
  },
  
});
