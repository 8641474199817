import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  StatusBar,
  Alert,
  Platform,
} from 'react-native';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCredential,
} from 'firebase/auth';
import { LinearGradient } from 'expo-linear-gradient';
import * as Google from 'expo-auth-session/providers/google';
import { auth, firestore } from '../../firebaseConfig';
import {
  doc,
  setDoc,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  writeBatch,
  getDoc,
} from 'firebase/firestore';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../App';
import * as WebBrowser from 'expo-web-browser';

WebBrowser.maybeCompleteAuthSession();

type SignupScreenProps = {
  navigation: StackNavigationProp<RootStackParamList, 'SignUpScreen'>;
};

export default function SignupScreen({ navigation }: SignupScreenProps) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [request, response, promptAsync] = Google.useAuthRequest({
    clientId: '893495440342-n7f9hc4qpvg01pbca34fob1sh61rqqek.apps.googleusercontent.com',
    iosClientId: '893495440342-o6vtfdlvv9s4q21fvid8sgahqahb0q18.apps.googleusercontent.com',
    androidClientId: '893495440342-hm95n7cll0egfo7v6nqe2068tra00la6.apps.googleusercontent.com',
    webClientId: '893495440342-n7f9hc4qpvg01pbca34fob1sh61rqqek.apps.googleusercontent.com',
    redirectUri: 'com.googleusercontent.apps.893495440342-o6vtfdlvv9s4q21fvid8sgahqahb0q18:/oauth2redirect/google',
  });
  

  const sendWelcomeEmail = async (to: string, displayName: string) => {
    try {
      await addDoc(collection(firestore, 'mail'), {
        to,
        message: {
          subject: 'Welcome to Aweyy!',
          html: `
            <html>
              <body style="font-family: Arial, sans-serif; background-color: #f9f9f9; margin: 0; padding: 0;">
                <div style="max-width: 600px; margin: auto; background: #ffffff; border-radius: 8px; overflow: hidden; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
                  <div style="background: #ffffff; padding: 20px; text-align: center;">
                    <img src="https://firebasestorage.googleapis.com/v0/b/aweyy-bdbf6.appspot.com/o/publicimages%2Flogo2.png?alt=media&token=98f79e84-7b9a-499e-bebb-2cc0b29c21a5" alt="Aweyy Logo" style="width: 150px;">
                  </div>
                  <div style="padding: 20px;">
                    <h1 style="color: #333;">Welcome to Aweyy, ${displayName}!</h1>
                    <p style="color: #555;">Thank you for signing up! We're thrilled to have you on board.</p>
                    <p style="color: #555;">Feel free to explore and enjoy our features!</p>
                    <img src="https://firebasestorage.googleapis.com/v0/b/aweyy-bdbf6.appspot.com/o/publicimages%2FDownloadbanner.png?alt=media&token=3aadbf8d-2aac-47cb-b6f9-f0647f1f16df" alt="Download App" style="width: 100%; border-radius: 8px; margin: 20px 0;">
                    <p style="color: #555;">Best regards,<br>The Aweyy Team</p>
                  </div>
                </div>
              </body>
            </html>
          `,
          text: `Hi ${displayName}, Welcome to Aweyy! We're thrilled to have you on board. Enjoy exploring our features!`,
        },
      });
    } catch (error) {
      console.error('Error sending welcome email:', error);
    }
  };

  const handleSignup = async () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    if (!name.trim()) {
      setErrorMessage('Please enter your name');
      return;
    }

    try {
      const loweredEmail = email.toLowerCase();
      const userCredential = await createUserWithEmailAndPassword(auth, loweredEmail, password);
      const user = userCredential.user;

      const userRef = doc(firestore, 'users', user.uid);
      await setDoc(userRef, {
        name,
        email: loweredEmail,
        role: 'explorer',
        createdAt: new Date().toISOString(),
        sharedList: [],
      });

      await sendWelcomeEmail(loweredEmail, name);
      navigation.replace('Location - Aweyy');
    } catch (error) {
      console.error('Error during signup:', error);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (response?.type === 'success') {
      const { id_token } = response.params;

      if (!id_token) {
        setErrorMessage('Google sign-in failed. Missing ID token.');
        return;
      }

      const credential = GoogleAuthProvider.credential(id_token);
      signInWithCredential(auth, credential)
        .then(async (userCredential) => {
          const user = userCredential.user;

          const userRef = doc(firestore, 'users', user.uid);
          const userDoc = await getDoc(userRef);

          if (!userDoc.exists()) {
            await setDoc(userRef, {
              name: user.displayName || 'User',
              email: user.email,
              role: 'explorer',
              createdAt: new Date().toISOString(),
            });
          }

          await sendWelcomeEmail(user.email, user.displayName || 'User');
          navigation.replace('Location - Aweyy');
        })
        .catch((error) => {
          console.error('Google Sign-In Error:', error);
          setErrorMessage(error.message);
        });
    }
  }, [response]);

  const handleGoogleSignup = () => {
    if (Platform.OS === 'web') {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const user = result.user;

          const userRef = doc(firestore, 'users', user.uid);
          const userDoc = await getDoc(userRef);

          if (!userDoc.exists()) {
            await setDoc(userRef, {
              name: user.displayName || 'User',
              email: user.email,
              role: 'explorer',
              createdAt: new Date().toISOString(),
            });
          }

          await sendWelcomeEmail(user.email, user.displayName || 'User');
          navigation.replace('Location - Aweyy');
        })
        .catch((error) => setErrorMessage(error.message));
    } else {
      promptAsync({ useProxy: true }).catch((error) => setErrorMessage(error.message));
    }
  };

  return (
    <LinearGradient colors={['#b6cc9b', '#ffffff']} style={styles.container}>
      <StatusBar barStyle="dark-content" backgroundColor="transparent" translucent />
      <View style={styles.innerContainer}>
        <Text style={styles.title}>Sign Up</Text>
        {errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}
        <TextInput placeholder="Your Name" style={styles.input} value={name} onChangeText={setName} />
        <TextInput placeholder="Email" style={styles.input} value={email} onChangeText={setEmail} />
        <TextInput placeholder="Password" secureTextEntry style={styles.input} value={password} onChangeText={setPassword} />
        <TextInput placeholder="Confirm Password" secureTextEntry style={styles.input} value={confirmPassword} onChangeText={setConfirmPassword} />
        <TouchableOpacity style={styles.actionButton} onPress={handleSignup}>
          <Text style={styles.actionButtonText}>Sign Up</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.googleButton} onPress={handleGoogleSignup}>
          <Text style={styles.googleButtonText}>Sign up with Google</Text>
        </TouchableOpacity>
        <View style={styles.divider} />
        <TouchableOpacity onPress={() => navigation.navigate('Login - Aweyy')}>
          <Text style={styles.linkText}>Already have an account? Login</Text>
        </TouchableOpacity>
      </View>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 20 },
  innerContainer: { width: '80%' },
  title: { fontSize: 40, fontWeight: 'bold', textAlign: 'center', marginBottom: 20, color: '#4A4A4A' },
  input: { height: 50, backgroundColor: '#fff', borderColor: '#b6cc9b', borderWidth: 2, borderRadius: 25, paddingHorizontal: 15, marginBottom: 20 },
  actionButton: { backgroundColor: '#b6cc9b', paddingVertical: 15, borderRadius: 25, alignItems: 'center', marginBottom: 20 },
  actionButtonText: { color: '#FFF', fontWeight: '600' },
  errorText: { color: 'red', marginBottom: 10, textAlign: 'center' },
  googleButton: { backgroundColor: '#4285F4', paddingVertical: 15, borderRadius: 25, alignItems: 'center', marginBottom: 20 },
  googleButtonText: { color: '#FFF', fontWeight: '600' },
  divider: { height: 1, backgroundColor: '#000', width: '100%', marginVertical: 20 },
  linkText: { color: '#4A4A4A', fontWeight: '700', textAlign: 'center' },
});
