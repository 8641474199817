import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Image, Platform, ActivityIndicator } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { firestore } from '../../firebaseConfig';
import { collection, getDocs, query, orderBy, doc, getDoc } from 'firebase/firestore';

type VendorCardProps = {
  vendor: {
    vendorName: string;
    location: string;
    profilePicture?: string;
    userId: string; // Ensure userId is available for fetching reviews
  };
};

export default function VendorCard({ vendor }: VendorCardProps) {
  const [averageRating, setAverageRating] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAverageRating = async () => {
      try {
        const reviewsRef = collection(firestore, 'vendors', vendor.userId, 'reviews');
        const reviewsQuery = query(reviewsRef, orderBy('createdAt', 'desc'));
        const reviewsSnapshot = await getDocs(reviewsQuery);

        let totalRating = 0;
        let reviewsCount = 0;

        reviewsSnapshot.forEach((review) => {
          const data = review.data();
          totalRating += data.rating || 0;
          reviewsCount += 1;
        });

        const avgRating = reviewsCount > 0 ? totalRating / reviewsCount : null;
        setAverageRating(avgRating);
      } catch (error) {
        console.error('Error fetching average rating:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAverageRating();
  }, [vendor.userId]);

  const renderStars = (rating: number) => {
    const stars = [1, 2, 3, 4, 5];
    return (
      <View style={styles.starContainer}>
        {stars.map((star) => (
          <Ionicons
            key={star}
            name={star <= rating ? 'star' : 'star-outline'}
            size={16} // Adjust star size
            color="#b6cc9b"
          />
        ))}
      </View>
    );
  };

  return (
    <View style={styles.cardContainer}>
      {vendor.profilePicture ? (
        <Image source={{ uri: vendor.profilePicture }} style={styles.profileImage} resizeMode="contain" />
      ) : (
        <Ionicons name="person-circle-outline" size={Platform.OS === 'web' ? 120 : 80} color="#b6cc9b" />
      )}
      <Text style={styles.vendorName}>{vendor.vendorName}</Text>
      <View style={styles.infoRow}>
        <Ionicons name="location-outline" size={Platform.OS === 'web' ? 24 : 20} color="#4A4A4A" />
        <Text style={styles.infoText}>{vendor.location}</Text>
      </View>

      {/* Display loading indicator or average rating */}
      {loading ? (
        <ActivityIndicator size="small" color="#b6cc9b" />
      ) : averageRating !== null ? (
        <View style={styles.ratingRow}>
          {renderStars(Math.round(averageRating))}
          <Text style={styles.ratingText}>{averageRating.toFixed(1)}</Text>
        </View>
      ) : (
        <Text style={styles.noRatingText}>No ratings yet</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: '#FFF',
    padding: Platform.OS === 'web' ? 20 : 15,
    borderRadius: 10,
    alignItems: 'center',
    borderWidth: 0.5,
    borderColor: '#b6cc9b',
    width: Platform.OS === 'web' ? 320 : 140,
  },
  profileImage: {
    width: Platform.OS === 'web' ? 160 : 80,
    height: Platform.OS === 'web' ? 160 : 80,
    borderRadius: Platform.OS === 'web' ? 80 : 40,
    marginBottom: 10,
  },
  vendorName: {
    fontSize: Platform.OS === 'web' ? 24 : 16,
    fontWeight: '600',
    color: '#4A4A4A',
    textAlign: 'center',
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  infoText: {
    fontSize: Platform.OS === 'web' ? 18 : 14,
    color: '#4A4A4A',
    marginLeft: 5,
  },
  ratingRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  starContainer: {
    flexDirection: 'row',
    marginRight: 5,
  },
  ratingText: {
    fontSize: Platform.OS === 'web' ? 14 : 14,
    color: '#4A4A4A',
  },
  noRatingText: {
    fontSize: Platform.OS === 'web' ? 16 : 14,
    color: '#999',
    marginTop: 8,
  },
});
