import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Linking,
  Image,
  ScrollView,
  TextInput,
} from 'react-native';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../App';

type WelcomeScreenProps = {
  navigation: StackNavigationProp<RootStackParamList, 'LoginScreen'>;
};

export default function WelcomeScreen({ navigation }: WelcomeScreenProps) {
  // Password protection state (for web only)
  const [isPasswordRequired, setIsPasswordRequired] = useState(Platform.OS === 'web');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handlePasswordSubmit = () => {
    if (password === 'AweyyForToday') {
      setIsPasswordRequired(false); // Allow access to Welcome screen
    } else {
      setErrorMessage('Incorrect password. Please try again.');
    }
  };

  const handleContinue = () => {
    navigation.navigate('SignUp - Aweyy');
  };

  const handleDownloadApp = (store: 'google' | 'apple') => {
    const urls = {
      google: 'https://play.google.com/store/apps',
      apple: 'https://apps.apple.com/us/app/aweyy/id6740799016',
    };
    Linking.openURL(urls[store]);
  };

  // Render "Coming Soon" screen for web with password protection
  if (isPasswordRequired) {
    return (
      <View style={styles.comingSoonContainer}>
        <Text style={styles.comingSoonTitle}>SOMETHING EXCITING IS COMING SOON</Text>
        <Text style={styles.comingSoonSubtitle}>
          Aweyy is launching soon! Enter the password to access the preview.
        </Text>
        <TextInput
          style={styles.passwordInput}
          placeholder="Enter password"
          placeholderTextColor="#999"
          secureTextEntry
          value={password}
          onChangeText={setPassword}
        />
        {errorMessage ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
        <TouchableOpacity style={styles.passwordButton} onPress={handlePasswordSubmit}>
          <Text style={styles.passwordButtonText}>Submit</Text>
        </TouchableOpacity>
      </View>
    );
  }

  // Original Welcome screen
  return (
    <View style={styles.outerContainer}>
      <LinearGradient colors={['#b6cc9b', '#ffffff']} style={styles.gradient}>
        <ScrollView contentContainerStyle={styles.scrollContent}>
          {/* Logo Image */}
          <Image source={require('../../assets/logo9.png')} style={styles.logo} />

          <Text style={styles.subtitle}>Explore curated places around the world, and share them with your friends.</Text>

          {Platform.OS === 'web' ? (
            <>
              <TouchableOpacity onPress={handleContinue} style={styles.button}>
                <Text style={styles.buttonText}>Continue on Web</Text>
              </TouchableOpacity>

              <Text style={styles.orText}>OR</Text>

              <View style={styles.downloadSection}>
                <Text style={styles.downloadText}>DOWNLOAD THE APP</Text>
                <View style={styles.storeButtons}>
                  <TouchableOpacity
                    style={styles.storeButton}
                    onPress={() => handleDownloadApp('google')}
                  >
                    <MaterialCommunityIcons
                      name="google-play"
                      size={24}
                      color="#FFF"
                      style={styles.storeIcon}
                    />
                    <Text style={styles.storeButtonText}>Google Play</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.storeButton}
                    onPress={() => handleDownloadApp('apple')}
                  >
                    <Ionicons
                      name="logo-apple"
                      size={24}
                      color="#FFF"
                      style={styles.storeIcon}
                    />
                    <Text style={styles.storeButtonText}>App Store</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </>
          ) : (
            <TouchableOpacity onPress={handleContinue} style={styles.button}>
              <Text style={styles.buttonText}>Continue</Text>
            </TouchableOpacity>
          )}
        </ScrollView>
      </LinearGradient>
    </View>
  );
}

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    height: '100vh', // Ensure it takes the full viewport height on web
    justifyContent: 'space-between',
  },
  gradient: {
    flex: 1,
    width: '100%',
  },
  scrollContent: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  logo: {
    width: 250,
    height: 250,
    marginBottom: 30,
    resizeMode: 'contain',
  },
  subtitle: {
    fontSize: 24,
    color: '#444',
    marginBottom: 70,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 15,
    width: 350,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 60,
  },
  buttonText: {
    fontSize: 18,
    color: '#FFF',
    fontWeight: '600',
  },
  orText: {
    fontSize: 16,
    color: '#4A4A4A',
    marginVertical: 20,
    fontWeight: 'bold',
  },
  downloadSection: {
    alignItems: 'center',
    marginTop: 10,
  },
  downloadText: {
    fontSize: 16,
    color: '#4A4A4A',
    marginBottom: 35,
    fontWeight: 'bold',
  },
  storeButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  storeButton: {
    flexDirection: 'row',
    backgroundColor: '#000000',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderRadius: 20,
    marginHorizontal: 20, // Increased margin between buttons
    alignItems: 'center',
  },
  storeIcon: {
    marginRight: 8,
  },
  storeButtonText: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: '600',
  },
  comingSoonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    padding: 20,
  },
  comingSoonTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 20,
    textAlign: 'center',
  },
  comingSoonSubtitle: {
    fontSize: 16,
    color: '#555',
    textAlign: 'center',
    marginBottom: 30,
  },
  passwordInput: {
    width: '80%',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    padding: 12,
    marginBottom: 20,
    textAlign: 'center',
  },
  errorMessage: {
    color: 'red',
    fontSize: 14,
    marginBottom: 10,
  },
  passwordButton: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 8,
  },
  passwordButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 16,
  },
});
