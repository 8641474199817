import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Modal,
  ScrollView,
  Image,
  Dimensions,
  Linking,
  Alert,
  Platform,
} from 'react-native';
import { firestore, auth, storage } from '../../firebaseConfig'; 
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../App';
import { doc, getDoc, deleteDoc, updateDoc, arrayUnion, arrayRemove, collection, query, where, getDocs, addDoc, orderBy } from 'firebase/firestore';
import { Ionicons } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';

type VendorProfileScreenProps = {
  navigation?: StackNavigationProp<RootStackParamList, 'VendorProfile'>;
  vendor?: any;
  isExplorer?: boolean;
  onClose?: () => void;
};

// Utility function to get the currency symbol based on location
const getCurrencySymbolForLocation = (location: string | null) => {
  const locationCurrencyMap: { [key: string]: string } = {
    'Bali': 'IDR',
    'Bangkok': 'THB',
    'Copenhagen': 'DKK',
    'Florence': 'EUR',
    'London': 'GBP',
    'Nice': 'EUR',
    'New York': 'USD',
    'Paris': 'EUR',
    'Tokyo': 'JPY',
  };

  return location ? locationCurrencyMap[location] || 'USD' : 'USD'; // Default to USD if location not found
};

// Confirmation dialog function
const confirmDelete = (item: string, callback: () => void) => {
  if (Platform.OS === 'web') {
    // Use window.confirm for web
    if (window.confirm(`Are you sure you want to delete ${item}?`)) {
      callback();
    }
  } else {
    // Use Alert.alert for mobile
    Alert.alert(
      'Confirm Delete',
      `Are you sure you want to delete ${item}?`,
      [
        { text: 'Cancel', style: 'cancel' },
        { text: 'Delete', style: 'destructive', onPress: callback },
      ],
      { cancelable: true }
    );
  }
};

export default function VendorProfileScreen({ vendor, isExplorer, onClose, navigation }: VendorProfileScreenProps) {
  const [vendorName, setVendorName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [googleMapsLocation, setGoogleMapsLocation] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [newPostModalVisible, setNewPostModalVisible] = useState(false);
  const [newPostImage, setNewPostImage] = useState<string | null>(null);
  const [newPostCaption, setNewPostCaption] = useState('');
  const [newPostPrice, setNewPostPrice] = useState(''); 
  const [posts, setPosts] = useState<Array<{ image: string; caption: string; price: string; aspectRatio: number }>>([]);
  const [selectedPost, setSelectedPost] = useState<any>(null); 
  const [isFavorited, setIsFavorited] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false); 
  const [friendEmail, setFriendEmail] = useState('');
  const [sharedVendors, setSharedVendors] = useState<Array<any>>([]);
  const [friendsList, setFriendsList] = useState<string[]>([]); 
  const [selectedFriend, setSelectedFriend] = useState<string | null>(null); 
  const [loadingFriends, setLoadingFriends] = useState(true); 
  const [showRatingReviewModal, setShowRatingReviewModal] = useState(false);
  const [showAllReviewsModal, setShowAllReviewsModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState('view');

  // State for reviews
  const [averageRating, setAverageRating] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [newRating, setNewRating] = useState(0);
  const [newReviewText, setNewReviewText] = useState('');

  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchVendorProfile = async () => {
      if (vendor) {
        if (!vendor.posts) {
          try {
            const vendorRef = doc(firestore, 'vendors', vendor.userId);
            const vendorSnap = await getDoc(vendorRef);
            if (vendorSnap.exists()) {
              const vendorData = vendorSnap.data();
              setVendorName(vendorData.vendorName);
              setDescription(vendorData.description);
              setSelectedLocation(vendorData.location);
              setSelectedCategory(vendorData.category);
              setGoogleMapsLocation(vendorData.googleMapsLocation || null);
              setProfilePicture(vendorData.profilePicture || null);
              setPosts(vendorData.posts || []);
            }
          } catch (error) {
            setErrorMessage('Error fetching vendor profile');
          }
        } else {
          setVendorName(vendor.vendorName);
          setDescription(vendor.description);
          setSelectedLocation(vendor.location);
          setSelectedCategory(vendor.category);
          setGoogleMapsLocation(vendor.googleMapsLocation || null);
          setProfilePicture(vendor.profilePicture || null);
          setPosts(vendor.posts || []);
        }
        setLoading(false);
      } else if (currentUser) {
        try {
          const vendorRef = doc(firestore, 'vendors', currentUser.uid);
          const vendorSnap = await getDoc(vendorRef);
          if (vendorSnap.exists()) {
            const vendorData = vendorSnap.data();
            setVendorName(vendorData.vendorName);
            setDescription(vendorData.description);
            setSelectedLocation(vendorData.location);
            setSelectedCategory(vendorData.category);
            setGoogleMapsLocation(vendorData.googleMapsLocation || null);
            setProfilePicture(vendorData.profilePicture || null);
            setPosts(vendorData.posts || []);
          }
        } catch (error) {
          setErrorMessage('Error fetching vendor profile');
        } finally {
          setLoading(false);
        }
      }
    };

    const checkIfFavorited = async () => {
      if (isExplorer && currentUser && vendor) {
        try {
          const userRef = doc(firestore, 'users', currentUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            if (userData.favorites && userData.favorites.includes(vendor.userId)) {
              setIsFavorited(true); 
            }
          }
        } catch (error) {
          console.error('Error checking if vendor is favorited:', error);
        }
      }
    };

    fetchVendorProfile();
    checkIfFavorited();
    fetchSharedVendors(); // Call to fetch shared vendors
    fetchVendorRatingsAndReviews(); // Fetch vendor ratings and reviews

  }, [vendor, currentUser, isExplorer]);

  const fetchSharedVendors = async () => {
    if (currentUser) {
      try {
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userSnap = await getDoc(userDocRef);
  
        if (userSnap.exists()) {
          const userData = userSnap.data();
          const sharedList = userData.sharedList || [];
  
          // Extract unique sharedWith emails where current user is the sharer
          const friends = sharedList
            .filter((item) => item.sharedBy === currentUser.email) // Ensure only items shared by the current user
            .map((item) => item.sharedWith)
            .filter(Boolean); // Remove undefined or null values
  
          setFriendsList([...new Set(friends)]); // Remove duplicates and update state
        } else {
          console.error('User document not found');
        }
      } catch (error) {
        console.error('Error fetching shared vendors:', error);
      } finally {
        setLoadingFriends(false);
      }
    }
  };
  

  const fetchVendorRatingsAndReviews = async () => {
    const userId = vendor?.userId || currentUser?.uid;
    if (userId) {
      try {
        const reviewsRef = collection(firestore, 'vendors', userId, 'reviews');
        const reviewsQuery = query(reviewsRef, orderBy('createdAt', 'desc'));
        const reviewsSnapshot = await getDocs(reviewsQuery);
  
        let totalRating = 0;
        const reviewsList = await Promise.all(
          reviewsSnapshot.docs.map(async (reviewDoc) => {
            const reviewData = reviewDoc.data();
            const userRef = doc(firestore, 'users', reviewData.userId);
            const userSnap = await getDoc(userRef);
  
            let userName = 'Unknown User';
            if (userSnap.exists()) {
              const userData = userSnap.data();
              userName = userData.name || userData.email || 'Unknown User';
            }
  
            totalRating += reviewData.rating; // Accumulate ratings
            return {
              ...reviewData,
              id: reviewDoc.id,
              userName,
            };
          })
        );
  
        const averageRating = reviewsList.length > 0 ? totalRating / reviewsList.length : 0;
  
        setReviews(reviewsList);
        setAverageRating(averageRating); // Update average rating state
      } catch (error) {
        console.error('Error fetching vendor ratings and reviews:', error);
      }
    }
  };
  

  // Handle review submission
  const handleSubmitReview = async () => {
    if (!newRating) {
      Alert.alert('Rating required', 'Please provide a rating before submitting.');
      return;
    }
  
    // Prevent vendors from reviewing their own profile
    if (currentUser?.uid === vendor?.userId) {
      Alert.alert('Restricted', 'You cannot review your own vendor profile.');
      return;
    }
  
    if (currentUser && vendor) {
      try {
        const reviewsRef = collection(firestore, 'vendors', vendor.userId, 'reviews');
        const existingReviewQuery = query(reviewsRef, where('userId', '==', currentUser.uid));
        const existingReviewSnapshot = await getDocs(existingReviewQuery);
  
        if (!existingReviewSnapshot.empty) {
          // Update the existing review
          const reviewDoc = existingReviewSnapshot.docs[0];
          await updateDoc(doc(reviewsRef, reviewDoc.id), {
            rating: newRating,
            reviewText: newReviewText || '',
            createdAt: new Date(),
          });
          Alert.alert('Review Updated', 'Your review has been successfully updated.');
        } else {
          // Add a new review
          await addDoc(reviewsRef, {
            userId: currentUser.uid,
            rating: newRating,
            reviewText: newReviewText || '',
            createdAt: new Date(),
          });
          Alert.alert('Review Submitted', 'Your review has been successfully submitted.');
        }
  
        // Refresh reviews and reset form state
        fetchVendorRatingsAndReviews();
        setNewRating(0);
        setNewReviewText('');
      } catch (error) {
        console.error('Error submitting review:', error);
        Alert.alert('Error', 'An error occurred while submitting your review. Please try again.');
      }
    }
  };
  

  const handleDeleteReview = async (reviewId) => {
    if (currentUser && vendor) {
      try {
        // Reference to the specific review document
        const reviewRef = doc(firestore, 'vendors', vendor.userId, 'reviews', reviewId);
  
        // Delete the review document
        await deleteDoc(reviewRef);
  
        // Recalculate average rating
        const reviewsRef = collection(firestore, 'vendors', vendor.userId, 'reviews');
        const reviewsSnapshot = await getDocs(reviewsRef);
        const totalReviews = reviewsSnapshot.size;
        const totalRating = reviewsSnapshot.docs.reduce((acc, doc) => acc + doc.data().rating, 0);
        const updatedAverageRating = totalReviews > 0 ? totalRating / totalReviews : 0;
  
        // Update vendor's average rating
        const vendorRef = doc(firestore, 'vendors', vendor.userId);
        await updateDoc(vendorRef, { averageRating: updatedAverageRating });
  
        // Refresh the reviews
        fetchVendorRatingsAndReviews();
      } catch (error) {
        console.error('Error deleting review:', error);
      }
    }
  };
  
  
  

  const handleAddToFavorites = async () => {
    if (currentUser && vendor) {
      try {
        const userRef = doc(firestore, 'users', currentUser.uid);
        if (isFavorited) {
          await updateDoc(userRef, {
            favorites: arrayRemove(vendor.userId),
          });
          setIsFavorited(false);
        } else {
          await updateDoc(userRef, {
            favorites: arrayUnion(vendor.userId),
          });
          setIsFavorited(true);
        }
      } catch (error) {
        console.error('Error updating favorites:', error);
      }
    }
  };

  const handleShareVendor = async () => {
    const emailToShare = selectedFriend || friendEmail.trim().toLowerCase();
    if (!emailToShare || !currentUser || !vendor) {
      Alert.alert('Error', 'Please enter a valid email.');
      return;
    }
  
    try {
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('email', '==', emailToShare));
      const querySnapshot = await getDocs(q);
  
      const currentUserDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
      const currentUserData = currentUserDoc.exists() ? currentUserDoc.data() : {};
  
      if (!currentUser.email) {
        throw new Error('Current user email is missing');
      }
  
      const vendorData = {
        vendorId: vendor.userId,
        location: selectedLocation || 'Unknown location',
        sharedBy: currentUser.email.toLowerCase(),
        sharedWith: emailToShare,
        sharedByName: currentUserData.name || 'Unknown',
        sharedByProfileImage: currentUserData.profileImage || null,
      };
  
      if (!querySnapshot.empty) {
        // Email belongs to an existing user
        const friendSnap = querySnapshot.docs[0];
        const friendRef = doc(firestore, 'users', friendSnap.id);
  
        await updateDoc(doc(firestore, 'users', currentUser.uid), {
          sharedList: arrayUnion(vendorData),
        });
  
        await updateDoc(friendRef, {
          sharedList: arrayUnion(vendorData),
        });
  
        // Send notification email to the existing user
        await addDoc(collection(firestore, 'mail'), {
          to: emailToShare,
          message: {
            subject: `Vendor Shared with You on Aweyy!`,
            html: `
              <html>
                <body style="font-family: Arial, sans-serif; background-color: #f9f9f9; margin: 0; padding: 0;">
                  <div style="max-width: 600px; margin: auto; background: #ffffff; border-radius: 8px; overflow: hidden; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
                    
                    <!-- Logo -->
                    <div style="background: #ffffff; padding: 20px; text-align: center;">
                      <img src="https://firebasestorage.googleapis.com/v0/b/aweyy-bdbf6.appspot.com/o/publicimages%2Flogo2.png?alt=media&token=98f79e84-7b9a-499e-bebb-2cc0b29c21a5" alt="Aweyy Logo" style="width: 150px;">
                    </div>
                    
                    <!-- Main Content -->
                    <div style="padding: 20px; font-size: 16px; color: #333;">
                      <p>Hi there,</p>
                      <p><strong>${currentUser.email}</strong> has shared a vendor with you on Aweyy!</p>
                      <p>Discover the vendor:</p>
                      <p><strong>${vendor.vendorName}</strong></p>
                      <p>${vendor.description}</p>
                      <p style="text-align: center; margin: 20px 0;">
                        <a href="https://aweyy.com" 
                           style="display: inline-block; background-color: #b6cc9b; color: #ffffff; padding: 10px 20px; text-decoration: none; font-weight: bold; border-radius: 8px;">
                           View Vendor
                        </a>
                      </p>
                      <p>Explore more unique vendors on Aweyy!</p>
                    </div>
                    
                    <!-- Footer -->
                    <div style="padding: 20px; text-align: center;">
                      <img src="https://firebasestorage.googleapis.com/v0/b/aweyy-bdbf6.appspot.com/o/publicimages%2FDownloadbanner.png?alt=media&token=3aadbf8d-2aac-47cb-b6f9-f0647f1f16df" alt="Download App Banner" style="width: 100%; border-radius: 8px;">
                    </div>
                    
                  </div>
                </body>
              </html>
            `,
          },
        });
  
        Alert.alert('Success', `Vendor shared with ${emailToShare}.`);
      } else {
        // Email doesn't belong to an existing user
        const confirmMessage =
          'This email does not belong to an existing user. Do you want to send an invitation?';
  
        const confirmed = Platform.OS === 'web'
          ? window.confirm(confirmMessage)
          : await new Promise((resolve) => {
              Alert.alert(
                'User Not Found',
                confirmMessage,
                [
                  { text: 'Cancel', style: 'cancel', onPress: () => resolve(false) },
                  { text: 'Send Invitation', onPress: () => resolve(true) },
                ],
                { cancelable: true }
              );
            });
  
        if (confirmed) {
          await addDoc(collection(firestore, 'sharedVendors'), {
            ...vendorData,
            isUser: false,
          });
  
          await addDoc(collection(firestore, 'mail'), {
            to: emailToShare,
            message: {
              subject: `You're Invited to Aweyy!`,
              html: `
                <html>
                  <body style="font-family: Arial, sans-serif; background-color: #f9f9f9; margin: 0; padding: 0;">
                    <div style="max-width: 600px; margin: auto; background: #ffffff; border-radius: 8px; overflow: hidden; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
                      
                      <!-- Logo -->
                      <div style="background: #ffffff; padding: 20px; text-align: center;">
                        <img src="https://firebasestorage.googleapis.com/v0/b/aweyy-bdbf6.appspot.com/o/publicimages%2Flogo2.png?alt=media&token=98f79e84-7b9a-499e-bebb-2cc0b29c21a5" alt="Aweyy Logo" style="width: 150px;">
                      </div>
                      
                      <!-- Main Content -->
                      <div style="padding: 20px; font-size: 16px; color: #333;">
                        <p>Hi there,</p>
                        <p><strong>${currentUser.email}</strong> wants to share a place with you on Aweyy!</p>
                        <p>Discover unique places and curated experiences tailored just for you.</p>
                        <p style="text-align: center; margin: 20px 0;">
                          <a href="https://aweyy.com/signup" 
                             style="display: inline-block; background-color: #b6cc9b; color: #ffffff; padding: 10px 20px; text-decoration: none; font-weight: bold; border-radius: 8px;">
                             Sign Up Now
                          </a>
                        </p>
                        <p>Don’t miss out on this opportunity to explore and connect with cool places around the world!</p>
                      </div>
                      
                      <!-- Footer -->
                      <div style="padding: 20px; text-align: center;">
                        <img src="https://firebasestorage.googleapis.com/v0/b/aweyy-bdbf6.appspot.com/o/publicimages%2FDownloadbanner.png?alt=media&token=3aadbf8d-2aac-47cb-b6f9-f0647f1f16df" alt="Download App Banner" style="width: 100%; border-radius: 8px;">
                      </div>
                      
                    </div>
                  </body>
                </html>
              `,
            },
          });
  
          Alert.alert('Invitation Sent', `An email has been sent to ${emailToShare}.`);
        }
      }
  
      setShareModalVisible(false);
      setFriendEmail('');
      setSelectedFriend(null);
    } catch (error) {
      console.error('Error sharing vendor:', error);
      Alert.alert('Error', 'There was a problem sharing the vendor.');
    }
  };
  
  
  
  
  

  const handlePostImagePicker = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.Images,
      allowsEditing: false,
      quality: 1,
    });

    if (!result.canceled) {
      setNewPostImage(result.assets[0].uri);  
    }
  };

  const handleSavePost = async () => {
    console.log("Post button pressed"); // Add logging
    if (!newPostImage) {
      setErrorMessage("Please upload an image");
      console.log("No image selected");
      return;
    }
  
    if (!currentUser) {
      setErrorMessage("User not authenticated");
      console.log("No user found");
      return;
    }
  
    try {
      console.log("Uploading image...");
      const storageRef = ref(storage, `vendorPosts/${currentUser.uid}/${Date.now()}`);
      const response = await fetch(newPostImage);
      const blob = await response.blob();
      await uploadBytes(storageRef, blob);
      console.log("Image uploaded");
  
      const downloadUrl = await getDownloadURL(storageRef);
      console.log("Download URL:", downloadUrl);
  
      const vendorRef = doc(firestore, 'vendors', currentUser.uid);
      const vendorSnap = await getDoc(vendorRef);
      const vendorData = vendorSnap.data();
  
      console.log("Calculating image aspect ratio...");
      const imageAspect = await new Promise<number>((resolve, reject) => {
        Image.getSize(
          newPostImage,
          (width, height) => resolve(height / width),
          (error) => reject(error)
        );
      });
  
      console.log("Updating posts...");
      const updatedPosts = [
        ...(vendorData?.posts || []),
        {
          image: downloadUrl,
          caption: newPostCaption,
          price: newPostPrice,
          aspectRatio: imageAspect,
          timestamp: Date.now(),
        },
      ];
  
      await updateDoc(vendorRef, { posts: updatedPosts });
      console.log("Post saved successfully");
  
      // Update local state
      setPosts(updatedPosts);
      setNewPostModalVisible(false);
      setNewPostImage(null);
      setNewPostCaption('');
      setNewPostPrice('');
    } catch (error) {
      console.error("Error saving post:", error);
      setErrorMessage("Error saving post");
    }
  };
  

  const handleDeletePost = (post: any) => {
    confirmDelete(`the post "${post.caption}"`, async () => {
      if (currentUser) {
        try {
          const storageRef = ref(storage, post.image);
          await deleteObject(storageRef);

          const vendorRef = doc(firestore, 'vendors', currentUser.uid);
          const updatedPosts = posts.filter((p) => p.image !== post.image);
          await updateDoc(vendorRef, { posts: updatedPosts });

          setPosts(updatedPosts);
        } catch (error) {
          console.error('Error deleting post:', error);
        }
      }
    });
  };

  const handlePostClick = (post: any) => {
    setSelectedPost(post);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  const getGridColumns = () => {
    const screenWidth = Dimensions.get('window').width;
    return screenWidth > 768 ? 4 : 2;
  };

  const goBackOrClose = () => {
    if (isExplorer) {
      onClose?.();
    } else {
      navigation?.goBack();
    }
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size='large' color="#b6cc9b" />
      </View>
    );
  }

  const StarRatingComponent = ({ rating, onRatingSelect, interactive = false, starSize = 30 }) => {
    const stars = [1, 2, 3, 4, 5];
    return (
      <View style={styles.starContainer}>
        {stars.map((star) => (
          <TouchableOpacity
            key={star}
            onPress={() => interactive && onRatingSelect(star)}
            disabled={!interactive}
          >
            <Ionicons
              name={star <= rating ? 'star' : 'star-outline'}
              size={starSize}  // Adjust the star size dynamically
              color={star <= rating ? '#b6cc9b' : '#b6cc9b'}
            />
          </TouchableOpacity>
        ))}
      </View>
    );
  };
  
  const renderReviewsCarousel = () => {
    if (!reviews || reviews.length === 0) {
      return (
        <View style={styles.noReviewsContainer}>
          <Text style={styles.noReviewsText}>No reviews available.</Text>
        </View>
      );
    }
  
    const latestReviews = reviews.slice(0, 5); // Fetch the latest 5 reviews
  
    return (
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.carouselContainer}
      >
        {latestReviews.map((review) => (
          <View key={review.id || Math.random().toString()} style={styles.reviewCard}>
            <Text style={styles.reviewerName}>{review.userName || 'Anonymous'}</Text>
            <StarRatingComponent rating={review.rating || 0} starSize={18} />
            <Text style={styles.reviewText}>
              {review.reviewText?.trim() || 'No review text available.'}
            </Text>
          </View>
        ))}
  
        {reviews.length > 5 && (
          <TouchableOpacity
            style={styles.seeAllButton}
            onPress={() => setShowAllReviewsModal(true)}
          >
            <Text style={styles.seeAllText}>See All</Text>
          </TouchableOpacity>
        )}
      </ScrollView>
    );
  };
  

  return (
    <View style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={styles.scrollContainer} style={styles.scrollView}>
        <View style={styles.profileContainer}>
          <TouchableOpacity style={styles.topCloseButton} onPress={goBackOrClose}>
            <Ionicons name={isExplorer ? "close" : "arrow-back"} size={30} color="#4A4A4A" />
          </TouchableOpacity>

           {/* Like Button in Top Right Corner */}
           <View style={styles.topRightButton}>
            <TouchableOpacity style={styles.favoriteButton} onPress={handleAddToFavorites}>
              <Ionicons
                name={isFavorited ? 'heart' : 'heart-outline'}
                size={32}
                color={isFavorited ? '#FFB6C1' : '#FFB6C1'}
              />
            </TouchableOpacity>
          </View>

        
        <View style={styles.profileHeader}>
          {profilePicture ? (
            <Image source={{ uri: profilePicture }} style={styles.profileImage} resizeMode="contain" />
          ) : (
            <Ionicons name="camera" size={100} color="#b6cc9b" />
          )}
        </View>
      

          <Text style={styles.vendorName}>{vendorName}</Text>
          <Text style={styles.description}>{description}</Text>

          <View style={styles.infoRow}>
            <Ionicons name="location-outline" size={20} color="#4A4A4A" />
            <Text style={styles.infoText}>{selectedLocation}</Text>
          </View>

          <View style={styles.infoRow}>
            <Ionicons name="pricetags-outline" size={20} color="#4A4A4A" />
            <Text style={styles.infoText}>{selectedCategory}</Text>
          </View>

          {/* Vendor Average Rating */}
                <StarRatingComponent rating={averageRating} starSize={22}  />

            {/* Button to show modal */}
            <TouchableOpacity onPress={() => setShowRatingReviewModal(true)}>
            <Text style={styles.linkText}>Go to Ratings and Reviews</Text>
          </TouchableOpacity>

         {/* Ratings and Reviews Modal */}
         <Modal
  visible={showRatingReviewModal}
  transparent={true}
  animationType="fade"
  onRequestClose={() => setShowRatingReviewModal(false)}
>
  <View style={styles.modalOverlay}>
    <View style={styles.modalContainer}>
      <TouchableOpacity
        style={styles.topCloseButton}
        onPress={() => setShowRatingReviewModal(false)}
      >
        <Ionicons name="close" size={24} color="#333" />
      </TouchableOpacity>

      <Text style={styles.modalTitle}>Ratings and Reviews</Text>

      <View style={styles.tabContainer}>
        <TouchableOpacity
          style={[
            styles.tabButton,
            selectedTab === 'view' && styles.activeTab,
          ]}
          onPress={() => setSelectedTab('view')}
        >
          <Text style={styles.tabText}>All Reviews</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.tabButton,
            selectedTab === 'write' && styles.activeTab,
          ]}
          onPress={() => setSelectedTab('write')}
        >
          <Text style={styles.tabText}>Leave a Review</Text>
        </TouchableOpacity>
      </View>

      {/* Tab Content */}
      {selectedTab === 'view' ? (
        <View style={styles.reviewsContainer}>
          {reviews.length > 0 ? (
            <ScrollView>
              {reviews.map((review) => (
                <View key={review.id} style={styles.reviewCard}>
                  <Text style={styles.reviewerName}>
                    {review.userName || 'Anonymous'}
                  </Text>
                  <StarRatingComponent rating={review.rating} starSize={18} />
                  <Text style={styles.reviewText}>
                    {review.reviewText || 'No review provided.'}
                  </Text>
                  {review.userId === currentUser?.uid && (
                    <View style={styles.reviewActions}>
                      <TouchableOpacity
                        onPress={() => {
                          setNewRating(review.rating);
                          setNewReviewText(review.reviewText);
                          setSelectedTab('write'); // Switch to write tab
                        }}
                      >
                        <Text style={styles.editButton}>Edit</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => handleDeleteReview(review.id)}
                      >
                        <Text style={styles.deleteButton}>Delete</Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              ))}
            </ScrollView>
          ) : (
            <Text style={styles.noReviewsText}>No reviews available.</Text>
          )}
        </View>
      ) : (
        <View style={styles.reviewForm}>
          <StarRatingComponent
            rating={newRating}
            onRatingSelect={setNewRating}
            interactive={true}
          />
          <TextInput
            style={styles.input}
            placeholder="Leave a review (optional)"
            placeholderTextColor="#666"
            value={newReviewText}
            onChangeText={setNewReviewText}
          />
          <TouchableOpacity
            style={styles.submitButton}
            onPress={handleSubmitReview}
          >
            <Text style={styles.submitButtonText}>Submit</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  </View>
</Modal>



{/* Modal for showing all reviews */}
<Modal
  visible={showAllReviewsModal}
  transparent={true}
  animationType="slide"
  onRequestClose={() => setShowAllReviewsModal(false)}
>
  <View style={styles.modalOverlay}>
    <View style={styles.modalContainer}>
      <TouchableOpacity style={styles.topCloseButton} onPress={() => setShowAllReviewsModal(false)}>
        <Ionicons name="close" size={24} color="#333" />
      </TouchableOpacity>

      <Text style={styles.modalTitle}>All Reviews</Text>
      <ScrollView style={styles.allReviewsContainer}>
        {reviews.map((review, index) => (
          <View key={index} style={styles.reviewCard}>
            <Text style={styles.reviewerName}>{review.userName}</Text>
            <StarRatingComponent rating={review.rating} starSize={18} />
            <Text style={styles.reviewText}>{review.reviewText || 'No review text'}</Text>

            {/* Show Edit/Delete options for the current user's review */}
            {review.userId === currentUser?.uid && (
              <View style={styles.reviewActions}>
                <TouchableOpacity
                  onPress={() => {
                    setNewRating(review.rating);
                    setNewReviewText(review.reviewText);
                    setShowRatingReviewModal(true); // Open modal for editing
                  }}
                >
                  <Text style={styles.editButton}>Edit</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleDeleteReview(review.id)}>
                  <Text style={styles.deleteButton}>Delete</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        ))}
      </ScrollView>
    </View>
  </View>
</Modal>



          {/* Add to Shared List - Explorer Only */}
          {isExplorer && (
            <>
              <View style={styles.buttonRow}>
   

    {/* Shared List Button */}
    <TouchableOpacity style={styles.sharedListButton} onPress={() => setShareModalVisible(true)}>
      <Text style={styles.buttonText}>Add to Shared List</Text>
    </TouchableOpacity>
  </View>

              {/* Modal for sharing */}
              <Modal visible={shareModalVisible} transparent={true} animationType="slide">
                <View style={styles.modalOverlay}>
                  <View style={styles.modalContainer}>
                    <Text style={styles.modalTitle}>Share with a Friend</Text>

                    {/* Show existing friends as buttons */}
                    {!loadingFriends && friendsList.length > 0 && (
  <>
    <Text style={styles.friendsListTitle}>Share with existing friends:</Text>
    {friendsList.map((friend, index) => (
      <TouchableOpacity
        key={index}
        style={[
          styles.friendButton,
          selectedFriend === friend ? styles.selectedFriendButton : null,
        ]}
        onPress={() => setSelectedFriend(friend)}
      >
        <Text style={styles.friendButtonText}>{friend}</Text>
      </TouchableOpacity>
    ))}
    <Text style={styles.orText}>or</Text>
  </>
)}


                    {/* Email Input for sharing */}
                    <TextInput
                      placeholder="Enter friend's email"
                      placeholderTextColor="#666"
                      style={styles.input}
                      value={friendEmail}
                      onChangeText={setFriendEmail}
                      editable={!selectedFriend} 
                    />

                    <TouchableOpacity style={styles.saveButton} onPress={handleShareVendor}>
                      <Text style={styles.saveButtonText}>Share</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.cancelButton} onPress={() => setShareModalVisible(false)}>
                      <Text style={styles.cancelButtonText}>Cancel</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Modal>
            </>
          )}

          {googleMapsLocation && (
            <TouchableOpacity
              style={styles.mapsButton}
              onPress={() => Linking.openURL(googleMapsLocation)}
            >
              <Text style={styles.mapsButtonText}>View on Google Maps</Text>
              <Ionicons name="map-outline" size={16} color="#333" />
            </TouchableOpacity>
          )}

          {!isExplorer && (
            <>
              <TouchableOpacity style={styles.button} onPress={() => navigation?.navigate('Edit Vendor Profile')}>
                <Text style={styles.buttonText}>Edit Profile</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={() => navigation?.navigate('RequestAd')}>
        <Text style={styles.buttonText}>Advertise or Promote your profile</Text>
      </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={() => setNewPostModalVisible(true)}>
                <Text style={styles.buttonText}>New Post</Text>
              </TouchableOpacity>
            </>
          )}

          <View style={styles.postsGrid}>
            {posts.map((post, index) => (
              <View key={index} style={[styles.postCard, { width: `${100 / getGridColumns() - 2}%` }]}>
                <TouchableOpacity onPress={() => handlePostClick(post)}>
                <Image
source={{ uri: post.image }}
  style={[
    styles.postImage,
    {
      width: '100%',
      height: Math.min(
        Dimensions.get('window').width / (post.aspectRatio || 1),
        300 // Set a max height to reduce zoom, adjust this value as needed
      ),
    },
  ]}
  resizeMode="cover"
/>

                </TouchableOpacity>
                <View style={styles.postInfo}>
                  {post.caption && <Text style={styles.postCaption}>{post.caption}</Text>}
                  {post.price && (
                    <Text style={styles.postPrice}>
                      {getCurrencySymbolForLocation(selectedLocation)} {post.price}
                    </Text>
                  )}
                </View>
                {!isExplorer && (
                  <TouchableOpacity style={styles.deleteButton} onPress={() => handleDeletePost(post)}>
                    <Text style={styles.deleteButtonText}>Delete</Text>
                  </TouchableOpacity>
                )}
              </View>
            ))}
          </View>
        </View>

        {/* Modal for New Post */}
        <Modal transparent={true} visible={newPostModalVisible} animationType="slide">
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalTitle}>Create New Post</Text>

              <TouchableOpacity onPress={handlePostImagePicker} style={styles.newPostImagePicker}>
                {newPostImage ? (
                  <Image source={{ uri: newPostImage }} style={styles.newPostImagePreview} />
                ) : (
                  <Ionicons name="camera" size={100} color="#b6cc9b" />
                )}
              </TouchableOpacity>

              <TextInput
                placeholder="Enter a caption (optional)"
                placeholderTextColor="#666"
                style={styles.input}
                value={newPostCaption}
                onChangeText={setNewPostCaption}
              />

              <TextInput
                placeholder="Enter price (optional)"
                placeholderTextColor="#666"
                style={styles.input}
                value={newPostPrice}
                keyboardType="numeric"
                onChangeText={setNewPostPrice}
              />

              <TouchableOpacity style={styles.saveButton} onPress={handleSavePost}>
                <Text style={styles.saveButtonText}>Post</Text>
              </TouchableOpacity>

              <TouchableOpacity style={styles.cancelButton} onPress={() => setNewPostModalVisible(false)}>
                <Text style={styles.cancelButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>

        {/* Modal to display full post */}
        {selectedPost && (
          <Modal transparent={true} visible={!!selectedPost} animationType="slide">
            <View style={styles.modalOverlay}>
              <View style={styles.modalContainer}>
                <Image source={{ uri: selectedPost.image }} style={styles.fullImage} resizeMode="contain" />
                {selectedPost.caption && <Text style={styles.fullCaption}>{selectedPost.caption}</Text>}
                {selectedPost.price && (
                  <Text style={styles.fullPrice}>
                    {getCurrencySymbolForLocation(selectedLocation)} {selectedPost.price}
                  </Text>
                )}
                <TouchableOpacity style={styles.topCloseButton} onPress={closeModal}>
                <Ionicons name="close" size={24} color="#333" />
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    backgroundColor: '#fff',
    paddingBottom: 100,
    flexGrow: 1,
  },
  scrollView: {
    height: '100vh',
  },
  profileContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    backgroundColor: '#fff',
    paddingTop: 100,
    paddingBottom: 50,
    minHeight: '100%',
  },
  profileHeader: {
    backgroundColor: '#fff',
    borderRadius: 100,
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  profileImage: {
    width: 150,
    height: 150,
    borderRadius: 100,
  },
  vendorName: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#4A4A4A',
    marginBottom: 10,
    textAlign: 'center',
  },
  
  tabContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: 35, // Adjust space between the close icon and tabs
  },
  tabButton: {
    flex: 1,
    paddingVertical: 10,
    alignItems: 'center',
    borderBottomWidth: 2,
    borderBottomColor: '#ccc',
  },
  activeTab: {
    borderBottomColor: '#b6cc9b',
  },
  tabText: {
    fontSize: 16,
    color: '#333',
    fontWeight: 'bold',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 20,
    textAlign: 'center',
  },
  
  description: {
    fontSize: 18,
    color: '#666',
    marginBottom: 20,
    textAlign: 'center',
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  infoText: {
    fontSize: 18,
    color: '#4A4A4A',
    marginLeft: 10,
  },
  button: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 12,
    paddingHorizontal: 30,
    borderRadius: 15,
    alignItems: 'center',
    marginVertical: 10,
    width: '80%',
  },
  buttonText: {
    color: '#333',
    fontWeight: '700',
    fontSize: 15,
  },
  cancelButton: {
    backgroundColor: '#F0F0F0',
    paddingVertical: 10,
    borderRadius: 15,
    alignItems: 'center',
    width: '100%',
  },
  cancelButtonText: {
    fontSize: 16,
    color: '#333',
    fontWeight: '600',
  },
  topCloseButton: {
    position: 'absolute',
    top: 40,
    left: 20,
    zIndex: 10, 
  },
  postsGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
  postCard: {
    backgroundColor: '#fff',
    borderRadius: 15,
    overflow: 'hidden',
    marginBottom: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 5,
  },
  postImage: {
    width: '100%',
    borderRadius: 10,
  },
  postInfo: {
    padding: 10,
    alignItems: 'center',
  },
  postCaption: {
    fontSize: 14,
    color: '#4A4A4A',
    textAlign: 'center',
  },
  postPrice: {
    fontSize: 16,
    color: '#555',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: 5,
  },
  deleteButton: {
    backgroundColor: '#FF5C5C',
    paddingVertical: 6, // Reduced padding for smaller button size
    paddingHorizontal: 15, // Adjusted padding to make it more compact
    borderRadius: 8, // Rounded corners
    marginTop: 5,
    width: '40%', // Adjusted width to fit better within the layout
    alignSelf: 'right', // Center the button
  },
  deleteButtonText: {
    color: '#333',
    fontWeight: '600', // Slightly reduced font weight
    fontSize: 14, // Reduced font size for a cleaner look
    textAlign: 'left',
  },
  newPostImagePicker: {
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 2,
    borderColor: '#b6cc9b',
    borderRadius: 15,
    marginBottom: 15,
  },
  newPostImagePreview: {
    width: '100%',
    height: '100%',
    borderRadius: 15,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContainer: {
    backgroundColor: '#FFF',
    borderRadius: 20,
    padding: 20,
    marginHorizontal: 10,
    maxHeight: '80%',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: '700',
    marginBottom: 20,
    textAlign: 'center',
  },
  input: {
    height: 50,
    width: '90%',
    backgroundColor: '#fff',
    borderColor: '#b6cc9b',
    borderWidth: 2,
    borderRadius: 15,
    paddingHorizontal: 15,
    marginBottom: 20,
    textAlign: 'center',
  },
  saveButton: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 15,
    borderRadius: 15,
    alignItems: 'center',
    marginBottom: 20,
    width: '90%',
  },
  saveButtonText: {
    color: '#333',
    fontWeight: '600',
  },
  fullImage: {
    width: '100%',
    height: 300,
    marginBottom: 15,
  },
  fullCaption: {
    fontSize: 18,
    color: '#4A4A4A',
    marginVertical: 10,
    textAlign: 'center',
  },
  fullPrice: {
    fontSize: 18,
    color: '#555',
    fontWeight: 'bold',
  },
  modalButton: {
    backgroundColor: '#FFB6C1',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 15,
    marginVertical: 5,
  },
  mapsButton: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    borderRadius: 15,
    borderWidth: 2,
    borderColor: '#333',
    marginVertical: 10,
    width: '60%',
    marginBottom: 25,
  },
  mapsButtonText: {
    color: '#333',
    fontWeight: '500',
    fontSize: 16,
    marginRight: 10,
  },
  friendsListTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 10,
  },
  friendButton: {
    backgroundColor: '#FFF0F6',
    padding: 10,
    borderRadius: 15,
    marginBottom: 5,
  },
  selectedFriendButton: {
    borderWidth: 2,
    borderColor: '#b6cc9b',
  },
  friendButtonText: {
    color: '#4A4A4A',
    fontWeight: '600',
  },
  orText: {
    marginVertical: 10,
    textAlign: 'center',
    color: '#666',
  },
  latestReview: {
    marginVertical: 20,
  },
  reviewTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 5,
  },
  reviewText: {
    fontSize: 16,
    color: '#666',
    marginTop: 5,
  },
  noReviewsText: {
    fontSize: 16,
    color: '#666',
    marginTop: 20,
  },
  reviewForm: {
    marginTop: 30,
    alignItems: 'center',
  },
  reviewFormTitle: {
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 15,
    justifyContent: 'center',
  },
  reviewActions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  editButton: {
    color: '#007BFF',
    fontWeight: '600',
  },
  deleteButton: {
    color: '#FF5C5C',
    fontWeight: '600',
  },
  
  submitButton: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 15,
    paddingHorizontal: 15,
    borderRadius: 15,
    alignItems: 'center',
    width: '90%',
    justifyContent: 'center'
  },
  submitButtonText: {
    color: '#333',
    fontWeight: '600',
  },
  starContainer: {
    flexDirection: 'row',
    marginVertical: 10,
    justifyContent: 'center'
  },
  linkText: {
    color: '#666',
    textDecorationLine: 'underline',
  },
  carouselContainer: {
    flexDirection: 'row',
    paddingTop: 10,
  },
  reviewCard: {
    width: 200,
    padding: 10,
    marginRight: 10,
    backgroundColor: '#b6cc9b',
    borderRadius: 15,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    height: 120,  
  },
  seeAllButton: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#b6cc9b',
    borderRadius: 15,
  },
  seeAllText: {
    color: '#FFF',
    fontWeight: '600',
  },
  allReviewsContainer: {
    paddingHorizontal: 10,
  },
  topRightButton: {
    position: 'absolute',
    top: 40, // Adjust for spacing from the top
    right: 20, // Adjust for spacing from the right
    zIndex: 10, // Ensure it appears on top of other elements
  },
  favoriteButton: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonRow: {
    flexDirection: 'row',  // Align items horizontally
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 10,
  },
  sharedListButton: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderRadius: 15,
    alignItems: 'center',
  },
});
