import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from '@react-navigation/native';

export default function HelpCenterScreen() {
  const navigation = useNavigation();
  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  const toggleItem = (item: string) => {
    setExpandedItem(expandedItem === item ? null : item);
  };

  return (
    <LinearGradient colors={['#b6cc9b', '#ffffff']} style={styles.background}>
      <SafeAreaView style={styles.safeArea}>
        {/* Header */}
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Ionicons name="arrow-back" size={22} color="#333" />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Help Center</Text>
        </View>

        <ScrollView contentContainerStyle={styles.scrollContainer}>
          <View style={styles.content}>
            <Text style={styles.label}>Frequently Asked Questions</Text>

            {/* FAQ Item: How do I update my profile? */}
            <TouchableOpacity style={styles.item} onPress={() => toggleItem('updateProfile')}>
              <Text style={styles.itemText}>How do I update my profile?</Text>
              <Ionicons
                name={expandedItem === 'updateProfile' ? 'chevron-up' : 'chevron-down'}
                size={20}
                color="#333"
              />
            </TouchableOpacity>
            {expandedItem === 'updateProfile' && (
              <Text style={styles.answerText}>
                To update your profile, go to the Profile page and click "Edit Profile".
              </Text>
            )}

            {/* FAQ Item: How do I contact support? */}
            <TouchableOpacity style={styles.item} onPress={() => toggleItem('contactSupport')}>
              <Text style={styles.itemText}>How do I contact support?</Text>
              <Ionicons
                name={expandedItem === 'contactSupport' ? 'chevron-up' : 'chevron-down'}
                size={20}
                color="#333"
              />
            </TouchableOpacity>
            {expandedItem === 'contactSupport' && (
              <Text style={styles.answerText}>
                You can contact support through the Contact Us page or by emailing heyy@aweyy.com.
              </Text>
            )}

            {/* FAQ Item: How do I report a problem? */}
            <TouchableOpacity style={styles.item} onPress={() => toggleItem('reportProblem')}>
              <Text style={styles.itemText}>How do I report a problem?</Text>
              <Ionicons
                name={expandedItem === 'reportProblem' ? 'chevron-up' : 'chevron-down'}
                size={20}
                color="#333"
              />
            </TouchableOpacity>
            {expandedItem === 'reportProblem' && (
              <Text style={styles.answerText}>
                To report a problem, visit the Contact Us page and provide a detailed description.
              </Text>
            )}

            {/* FAQ Item: Can I recommend a shop? */}
            <TouchableOpacity style={styles.item} onPress={() => toggleItem('recommendShop')}>
              <Text style={styles.itemText}>Can I recommend a shop?</Text>
              <Ionicons
                name={expandedItem === 'recommendShop' ? 'chevron-up' : 'chevron-down'}
                size={20}
                color="#333"
              />
            </TouchableOpacity>
            {expandedItem === 'recommendShop' && (
              <Text
                style={[styles.answerText, styles.linkText]}
                onPress={() => navigation.navigate('Recommendations')}
              >
                Absolutely! Press me, fill out the form, and we'll
                review your suggestion.
              </Text>
            )}

            {/* FAQ Item: Where can I find the privacy policy? */}
            <TouchableOpacity style={styles.item} onPress={() => toggleItem('privacyPolicy')}>
              <Text style={styles.itemText}>Where can I find the privacy policy?</Text>
              <Ionicons
                name={expandedItem === 'privacyPolicy' ? 'chevron-up' : 'chevron-down'}
                size={20}
                color="#333"
              />
            </TouchableOpacity>
            {expandedItem === 'privacyPolicy' && (
              <Text
                style={[styles.answerText, styles.linkText]}
                onPress={() => navigation.navigate('PrivacyPolicy')}
              >
                You can find the privacy policy here.
              </Text>
            )}

            {/* Contact Support Button */}
            <TouchableOpacity
              style={styles.contactButton}
              onPress={() => navigation.navigate('Contact')}
            >
              <Text style={styles.contactButtonText}>Contact Support</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </SafeAreaView>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },
  safeArea: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  backButton: {
    padding: 5,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333',
    marginLeft: 10,
  },
  scrollContainer: {
    paddingHorizontal: 20,
    paddingTop: 10,
    paddingBottom: 20,
  },
  content: {
    flex: 1,
  },
  label: {
    fontSize: 16,
    fontWeight: '600',
    color: '#333',
    marginBottom: 15,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    borderColor: '#b6cc9b',
    borderWidth: 1,
    borderRadius: 15,
    paddingHorizontal: 15,
    paddingVertical: 12,
    marginBottom: 10,
  },
  itemText: {
    fontSize: 14,
    fontWeight: '500',
    color: '#333',
  },
  answerText: {
    fontSize: 14,
    color: '#333',
    marginVertical: 10,
    paddingHorizontal: 15,
  },
  linkText: {
    color: '#333',
    textDecorationLine: 'underline',
  },
  contactButton: {
    backgroundColor: '#b6cc9b',
    paddingVertical: 15,
    borderRadius: 15,
    alignItems: 'center',
    marginTop: 20,
  },
  contactButtonText: {
    fontSize: 18,
    color: '#FFF',
    fontWeight: '600',
  },
});
