import React from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

export default function PrivacyPolicyScreen() {
  const navigation = useNavigation();

  return (
    <LinearGradient colors={['#b6cc9b', '#ffffff']} style={styles.background}>
      <SafeAreaView style={styles.safeArea}>
        {/* Header */}
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Ionicons name="arrow-back" size={22} color="#333" />
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Privacy Policy</Text>
        </View>

        {/* Content */}
        <ScrollView contentContainerStyle={styles.contentContainer}>
          <Text style={styles.sectionTitle}>Privacy Policy</Text>

          <Text style={styles.content}>
            Effective Date: 1 January 2025
          </Text>

          <Text style={styles.content}>
            AWEYY values your privacy. This Privacy Policy explains how we collect, use, and protect your information when you use our app. By accessing or using AWEYY, you agree to the terms outlined in this Privacy Policy.
          </Text>

          <Text style={styles.subTitle}>1. Information We Collect</Text>
          <Text style={styles.content}>
            1.1 Account Information: When you create an account, we collect your name, email address, and other necessary details.{'\n\n'}
            1.2 Usage Data: We collect data about how you use the app, such as interactions with vendors and features.{'\n\n'}
            1.3 Device Information: We may collect information about your device, including its type, operating system, and unique identifiers.
          </Text>

          <Text style={styles.subTitle}>2. How We Use Your Information</Text>
          <Text style={styles.content}>
            2.1 To Improve the App: Your data helps us enhance your experience and develop new features.{'\n\n'}
            2.2 Communication: We may use your information to send notifications, updates, and customer support messages.{'\n\n'}
            2.3 Personalization: We use your preferences and activity to tailor the app experience.
          </Text>

          <Text style={styles.subTitle}>3. Sharing Your Information</Text>
          <Text style={styles.content}>
            3.1 Vendors: We share information like reviews and interactions with vendors to facilitate connections.{'\n\n'}
            3.2 Third-Party Services: We may share data with third-party services for app functionality, analytics, and marketing.{'\n\n'}
            3.3 Legal Requirements: Your information may be disclosed if required by law or to protect our rights.
          </Text>

          <Text style={styles.subTitle}>4. Your Privacy Choices</Text>
          <Text style={styles.content}>
            4.1 Account Settings: You can update your information and preferences through the app settings.{'\n\n'}
            4.2 Data Deletion: To delete your account and associated data, contact us at heyy@aweyy.com.
          </Text>

          <Text style={styles.subTitle}>5. Data Security</Text>
          <Text style={styles.content}>
            We prioritize the security of your data and implement appropriate measures. However, no system is completely secure, and we cannot guarantee absolute security.
          </Text>

          <Text style={styles.subTitle}>6. Changes to This Policy</Text>
          <Text style={styles.content}>
            We may update this Privacy Policy periodically. Users will be notified of significant changes. Continued use of the app constitutes acceptance of the updated policy.
          </Text>

          <Text style={styles.subTitle}>7. Contact Us</Text>
          <Text style={styles.content}>
            If you have questions or concerns about this Privacy Policy, contact us at:{'\n'}
            Email: heyy@aweyy.com{'\n'}
          </Text>
        </ScrollView>
      </SafeAreaView>
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },
  safeArea: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  backButton: {
    padding: 5,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333',
    marginLeft: 10,
  },
  contentContainer: {
    padding: 20,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#4A4A4A',
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: '#555',
    marginTop: 20,
    marginBottom: 10,
  },
  content: {
    fontSize: 14,
    lineHeight: 22,
    color: '#666',
    textAlign: 'justify',
  },
});
